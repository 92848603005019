export default {
  home: {
    seo: {
      title: "Bienes raíces en Miami y Orlando {'|'} PFS Realty Group",
      description:
        'PFS Realty Group te brinda servicios especializados en bienes raíces en Miami y Orlando, conoce la lista de propiedades disponibles.'
    },
    hero: {
      title: 'Bienes raíces en venta y alquiler en Miami',
      p: 'Propiedades',
      f: 'Financiamiento',
      s: 'Servicios'
    },
    used: 'Usado',
    new: 'Nuevo',
    rent: 'Arrendar',
    buy: 'Usado',
    location: 'Ciudad, código postal o condado',
    currentLocation: 'Ubicación actual',
    contactFormTitle:
      '¡Contacta a uno de nuestros asesores para más información!',
    contactFormText:
      'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
    properties_title: 'Nuestro portafolio de propiedades',
    propertiesPortfolio: 'Departamentos y Casas en venta y arriendo en Miami',
    apartmentsPortfolioDescription: 'Nuestro portafolio de departamentos',
    housesPortfolioDescription: 'Nuestro portafolio de casas',
    find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
    find_property_text:
      'Tenemos un amplio portafolio de propiedades perfectas para ti.',
    clients: {
      title:
        '2.000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
      features: {
        one: {
          title: '22 años de experiencia en ventas de propiedades en EE.UU.',
          description:
            'Nuestra prolongada trayectoria como líderes en el mercado de bienes raíces en Miami nos posiciona como tu mejor opción. Tenemos un historial comprobado que respalda nuestro servicio confiable y resultados exitosos.'
        },
        two: {
          title: '65 asesores en 12 países de América y Europa',
          description:
            'Con una red internacional de asesores, ofrecemos perspectivas globales y localizadas que enriquecen nuestra capacidad para encontrar oportunidades inigualables. Desde el asesoramiento hasta el cierre del trato, optimizamos tus proyectos de inversión inmobiliaria.'
        },
        three: {
          title: 'Más de 2.000 clientes satisfechos confían en nosotros',
          description:
            'La confianza depositada en nosotros por nuestros clientes es el reflejo de nuestra dedicación y dominio del mercado de bienes raíces. Somos una de las inmobiliarias en Miami más confiables y respetadas. ¡Contáctenos y experimente el éxito con PFS Realty!'
        }
      }
    },
    services: {
      title: 'Servicios completos',
      properties: {
        title: 'Adquiera la vivienda ideal',
        description:
          'Encuentre la propiedad perfecta en Miami y compra inmuebles nuevos y usados de alta calidad. Cada asesor inmobiliario cuenta con amplia experiencia y conocimiento para brindarle el asesoramiento especializado que necesita.'
      },
      financing: {
        title: 'Financiamiento a su medida',
        description:
          'La compra de un inmueble suele requerir de un financiamiento sólido y adaptado a la capacidad económica del comprador. Por eso, ofrecemos créditos hipotecarios en Estados Unidos flexibles y personalizados para ecuatorianos con mínimo 30% de entrada. Adaptamos las condiciones a sus necesidades.'
      },
      extra_income: {
        title: 'Genere ingresos extras alquilando',
        description:
          'Le brindamos una atención y asistencia integral para convertir tu propiedad en una fuente de ingresos a través de la renta en dólares, manejando todas las etapas del arrendamiento para maximizar un retorno de inversión óptimo.'
      },
      management: {
        title: 'Administración de inmuebles',
        description:
          'Contamos con un equipo preparado para el mantenimiento profesional y el cuidado preventivo y correctivo de su vivienda. Optimizamos recursos y beneficios para asegurar una administración eficiente en su inversión inmobiliaria.'
      },
      legal: {
        title: 'Asesoría fiscal y contable completa ',
        description:
          'Nuestro equipo multidisciplinario de profesionales proporciona información experta para aclarar todas las dudas sobre los aspectos legales y contables que involucran la compra, venta y alquiler de bienes inmuebles. Aseguramos el cumplimiento legal de los contratos.'
      }
    },
    events: {
      title: 'Próximos eventos',
      formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
      registerButton: 'Reserva Entrada'
    },
    testimonialsTitle: 'Testimoniales',
    news_title: 'Nuestro Blog',
    popupFormTitle:
      'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
    popupFormMessage:
      'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
  },
  search: {
    buyTypeOptions: {
      rent: 'Arrendar',
      rented: 'Arrendado | Arrendados'
    },
    availableItems: '{items} en venta y arriendo',
    rentedItems: '{items} arrendados',
    rentedItems_female: '{items} arrendadas',
    soldOrRentedItems: '{items} vendidos o arrendados',
    soldOrRentedItems_female: '{items} vendidas o arrendadas',
    itemsForRent: '{items} en arriendo',
    rentSearchResults: '{items} en arriendo en {search}',
    rentedSearchResults: '{items} arrendadas en {search}',
    buyRentSearchResults: '{items} en venta y arriendo en {search}',
    rentSearchResultsNearYou: '{0} en arriendo cerca de tu ubicación',
    rentedSearchResultsNearYou: '{0} arrendadas cerca de tu ubicación',
    buyRentSearchResultsNearYou:
      '{0} en venta y arriendo cerca de tu ubicación',
    rentResults: 'Propiedades en arriendo',
    rentedResults: 'Propiedades arrendadas',
    title: 'Búsqueda de casas, departamentos y otros inmuebles',
    properties: 'Casas y departamentos',
    condoType: 'Departamento',
    condosType: 'Departamentos',
    apartmentsCondoType: 'Condominio de departamentos',
    apartment: 'Departamento',
    apartments: 'Departamentos'
  },
  project: {
    rentals: 'Arriendos',
    availableCondosForRent: 'Departamentos disponibles para arrendar',
    pendingCondosForRent: 'Departamentos pendientes para arrendar',
    closedCondosForRent: 'Departamentos arrendados',
    availableCondosForSale: 'Departamentos disponibles para la venta',
    pendingCondosForSale: 'Departamentos pendientes para la venta',
    closedCondosForSale: 'Departamentos vendidos',
    condos:
      '{project} tiene {n} departamento(s) con las opciones seleccionadas con un precio por pie cuadrado promedio de {price}'
  },
  property: {
    rented: 'Arrendado',
    rented_female: 'Arrendada'
  },
  footer: {
    rent_label: 'Precio de Arriendo'
  },
  calculator: {
    title: 'Simulador de crédito hipotecario',
    description:
      'Esta herramienta te permite simular el pago mensual de un préstamo hipotecario, considerando factores como el capital e interés, el impuesto predial anual, la cuota de administración y la prima de seguro mensual. <br/>' +
      'Al ingresar los datos sobre el precio del inmueble, la cuota inicial y la tasa de interés, puedes obtener un cálculo preciso de tus cuotas mensuales, facilitando la compra y financiamiento de tu vivienda a través de una entidad financiera. <br/>',
    hint: 'Esto es esencial para la educación financiera y la planificación de la compra de hogar propio, permitiéndole gestionar mejor su historial crediticio y ahorros.',
    monthlyPayments: 'Cuotas mensuales',
    principalInterest: 'Principal e intereses',
    propertyTax: 'Impuesto predial anual',
    associationFee: 'Cuota de asociación',
    principalInterestLegend: 'Principal e intereses',
    propertyTaxLegend: 'Impuesto predial anual',
    associationFeeLegend: 'Cuota de asociación',
    insuranceFee: 'Prima de seguro',
    homePrice: 'Precio',
    propertyTaxYearly: 'Predial anual',
    associationFeeMonthly: 'Cuota de asociación mensual',
    insuranceFeeMonthly: 'Prima de seguro mensual',
    downPayment: 'Cuota inicial (%)',
    interestRate: 'Tasa de Interés (%)',
    interestRatePlaceholder: 'Tasa de interés (%)',
    loanType: 'Tipo de crédito',
    loanType10Year: 'Fijo a 10 Años',
    loanType15Year: 'Fijo a 15 Años',
    loanType20Year: 'Fijo a 20 Años',
    loanType30Year: 'Fijo a 30 Años',
    disclaimer: '*Los cálculos son un estimado.'
  },
  marketReport: {
    help:
      'Mediante nuestro informe inmobiliario de Miami y Orlando, se puede seleccionar  la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada, comparar precios de propiedades en diversas áreas, evaluar el impacto de las tendencias e inversiones en el sector, y prever cambios en el mercado para planificar de manera óptima tus estrategias de compra, venta o arrendamiento. <br/>' +
      'Esta herramienta te permite acceder a datos actualizados y detallados, segmentados por provincia, ciudad, parroquia y tipo de inmueble, resultando fundamental para tomar decisiones informadas y planificadas en el sector inmobiliario. Aprovecha esta valiosa información y consulta el informe completo para optimizar tus inversiones.<br/>',
    longTitle:
      'Reporte de mercado inmobiliario: Datos actualizados para invertir',
    description:
      'Accede a nuestro reporte de mercado inmobiliario y toma decisiones informadas en Miami y Orlando. Compara precios y optimiza tus inversiones',
    recentlyRented: 'Recientemente arrendados',
    buyTypes: {
      rentals: 'Arriendos'
    }
  },
  blog: {
    title: 'Oportunidades en Miami y Orlando',
    subtitle:
      'Descubre las últimas tendencias en el mercado inmobiliario y mantente informado con nuestras noticias exclusivas. ¡No te pierdas ninguna actualización sobre el sector y toma decisiones acertadas para tu futuro hogar o inversión!',
    description:
      'Conoce los temas más relevantes sobre inversiones inmobiliarias en Miami y Orlando. Ingresa ahora al blog de PFS Realty.',
    seo: {
      title: "Blog Informativo sobre Bienes Raíces {'|'} PFS Realty"
    }
  },
  seo: {
    defaultSingle: 'Casa o departamento',
    defaultMultiple: 'Casas y departamentos',
    multipleSale: 'Casas y departamentos en venta',
    defaultDescription:
      'En PFS Realty encuentra bienes raices e inmuebles en venta y arriendo en la Florida.',
    singleRent: '{0} en arriendo',
    singleRentAlt: '{0} en arriendo',
    multipleRent: 'Casas y departamentos en arriendo',
    complementaryTitle:
      'en PFSRealty encuentra bienes raices e inmuebles en venta y arriendo.',
    counties: {
      description:
        'Encuentra propiedades en venta y arriendo en los condados de la Florida.'
    },
    cities: {
      description:
        'Encuentra propiedades en venta y arriendo en las ciudades de {0}, Florida.'
    },
    neighborhoods: {
      description:
        'Encuentra propiedades en venta y arriendo en los vecindarios de {0}, Florida.'
    },
    calculator: {
      longTitle:
        'Simulador de crédito hipotecario: Invierte en Miami desde Ecuador',
      description:
        'Usa nuestro simulador de crédito hipotecario y calcula tus pagos. ¡Planifica tu inversión en propiedades en Miami de manera rápida y precisa!'
    }
  }
};
